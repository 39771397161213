import React from "react";
import Hero from "../../components/hero";
import BlogCard from "../../components/blogCard";
import Layout from "../../components/layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Link, useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Navigation from "../../components/navigation";
import ServiceHeroMobile from "../../components/service-hero-mobile";
import { Helmet } from "react-helmet";
import SVGBP1 from "../../components/svg/bp1-svg";
import SVGBP2 from "../../components/svg/bp2-svg";

const BlogPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				heroImg: wpMediaItem(title: { eq: "Home-Hero-Image-HP" }) {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}

				blogPosts: allWpPost(
					sort: { fields: dateGmt, order: DESC }
					filter: { title: { ne: "Website Images" } }
				) {
					nodes {
						dateGmt(formatString: "DD.MM.yyyy")
						id
						title
						slug
						excerpt
						blogFields {
							featureImage {
								node {
									altText
									localFile {
										childImageSharp {
											gatsbyImageData(
												quality: 100
												placeholder: BLURRED
												layout: CONSTRAINED
											)
										}
									}
								}
							}
						}
					}
				}
			}
		`
	);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Blog",
				item: {
					url: `${siteUrl}/blog`,
					id: `${siteUrl}/blog`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<div className="position-relative">
				<GatsbySeo
					title="Blog | Colston Ltd"
					description="Blog Page Colston Ltd"
					language="en"
					openGraph={{
						type: "website",
						url: `${siteUrl}/blog`,
						title: "Blog | Colston Ltd",
						description: "Blog Page Colston Ltd",
						images: [
							{
								url: `${data.heroImg?.localFile.publicURL}`,
								width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
								height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
								alt: `${data.heroImg?.altText}`,
							},
						],
					}}
				/>
				<section className="pb-7">
					<ServiceHeroMobile
						title="Blog"
						description={
							<Row>
								{data.blogPosts.nodes.map((post) => (
									<BlogCard
										key={post.id}
										title={post.title}
										uri={post.slug}
										date={post.dateGmt}
										image={
											post.blogFields.featureImage.node.localFile
												.childImageSharp.gatsbyImageData
										}
										imageAlt={post.blogFields.featureImage.node.altText}
									></BlogCard>
								))}
							</Row>
						}
						ctaText={null}
					/>
					<Container
						style={{ minHeight: "100vh" }}
						className="position-relative d-none d-lg-block py-0 my-0 px-0 mx-0"
						fluid
					>
						<div className="position-absolute top-0 w-100">
							<Navigation />
						</div>

						<Row className="mx-0 px-0  h-100">
							<Col
								style={{ marginTop: "11vh" }}
								className="pt-8 d-flex ps-xl-8 ps-lg-4 "
								lg={4}
							>
								<div className="d-block">
									<h1 className="text-primary display-4 fw-bold">Blog</h1>
									<hr className="bg-secondary  hr-big w-80 " />
								</div>
							</Col>
							<Col className="pe-0 me-0" lg={8}>
								<div
									style={{
										paddingTop: "11vh",
										borderTopLeftRadius: "8vh",
										borderBottomRightRadius: "8vh",
									}}
									className="bg-primary position-relative    text-white "
								>
									<SVGBP2
										style={{ zIndex: "1" }}
										className="position-absolute d-none d-lg-block bottom-10 end-0"
									/>
									<Row
										style={{ zIndex: "2" }}
										className="pt-7 position-relative px-5"
									>
										{data.blogPosts.nodes.map((post) => (
											<BlogCard
												key={post.id}
												title={post.title}
												uri={post.slug}
												date={post.dateGmt}
												image={
													post.blogFields.featureImage.node.localFile
														.childImageSharp.gatsbyImageData
												}
												imageAlt={post.blogFields.featureImage.node.altText}
											></BlogCard>
										))}
									</Row>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<SVGBP1
					style={{ zIndex: "-1" }}
					className="position-absolute d-none d-lg-block bottom-0 start-0"
				/>
			</div>
		</Layout>
	);
};

export default BlogPage;
